<template>
  <section class="entity-section entity-section--entdet entdet">

    <!-- entity header -->
    <div class="entdet__head">
      <div
        class="entdet__logo"
        v-if="entity.logo"
        :style="{backgroundImage: `url(${entity.logo})`}"></div>
      <div class="entdet__desc">
        <h1 class="entdet__title">{{entity.acronim}}</h1>
        <div>
          <span>{{entity.name}}</span>
        </div>
      </div>
      <div class="entdet__dates">
        <div v-if="self?.id && entity.created_by === self.id">
          <InputCheckbox
            name="entity-ispublic"
            :label="$t('common.make_public')"
            v-model="isPublic">
          </InputCheckbox>
        </div>
        <div>
          <span>{{$t('common.calification')}}:</span>
          <span><strong>{{getScore(entity)}}</strong></span>
        </div>
        <div>
          <span>{{$t('common.last_revision')}}:</span>
          <span v-if="entity.last_report?.updated">{{filterDate(entity.last_report.updated)}}</span>
        </div>
        <div>
          <span>{{$t('common.validator')}}:</span>
          <span>OpenODS SL</span>
        </div>
      </div>
    </div>
    <!-- end entity header -->

    <!-- entity information -->
    <div class="entdet__infoblock">
      <div v-show="entity.type">
        <span>{{$t('common.type')}}</span>
        <span>
          <router-link :to="{name: 'home', query: {type: entity.type}, params: { locale: $i18n.locale }}">
            {{$t(`entityType.${entity.type}`)}}
          </router-link>
        </span>
      </div>
      <div v-show="entity.sector">
        <span>{{$t('common.sector')}}</span>
        <span>
          <router-link :to="{name: 'home', query: {sector: entity.sector}, params: { locale: $i18n.locale }}">
            {{$t(`entitySector.${entity.sector}`)}}
          </router-link>
        </span>
      </div>
      <div v-show="entity.vat_number">
        <span>{{$t('common.vat_number')}}</span>
        <span>{{entity.vat_number}}</span>
      </div>
      <div v-show="entity.address">
        <span>{{$t('common.address')}}</span>
        <span>{{entity.address}}</span>
      </div>
      <div v-show="entity.web">
        <span>{{$t('common.web')}}</span>
        <span>
          <a :href="entity.web" target="_blank" rel="noopener">{{filterUrl(entity.web)}}</a>
        </span>
      </div>
      <div v-show="entity.email">
        <span>{{$t('common.email')}}</span>
        <span><a :href="`mailto:${entity.email}`">{{entity.email}}</a></span>
      </div>
      <div v-show="entity.population">
        <span>{{getPopulationName(entity)}}</span>
        <span>{{entity.population?.toLocaleString($i18n.locale)}}</span>
      </div>
      <div v-show="entity.income">
        <span>{{$t('common.sales')}}</span>
        <span>{{filterCurrency(entity.income)}} €</span>
      </div>
      <div v-if="entity.social_media && entity.social_media.length">
        <span>{{$t('common.networks')}}</span>
        <span>
          <a
            v-for="(media, m) in entity.social_media" :key="m"
            class="entdet__social"
            :class="`entdet__social--${media.platform.toLowerCase()}`"
            :title="capitalize(media.platform)"
            rel="noopener"
            :href="media.url"
            target="_blank"></a>
        </span>
      </div>
    </div>
    <!-- end entity information -->

    <!-- entity contacts -->
    <div class="entdet__contacts" v-if="entity.last_report?.contacts">
      <ContactComponent
        v-for="(contact, i) in entity.last_report.contacts"
        :key="i"
        :contact="contact"></ContactComponent>
    </div>
    <!-- end entity contacts -->

    <!-- entity shares -->
    <div class="entdet__shares" v-if="entity.last_report?.shares">
      <a v-for="(share, i) in getGeneralShares()" :key="i" :href="share.url" target="_blank">
      <Button
        :isLoading="false"><span>{{share.type}}</span></Button>
      </a>
    </div>
    <!-- end entity shares -->

  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import ContactComponent from '@/components/contacts/ContactComponent.vue';

export default {
  name: 'EntityDetailComponent',
  components: {
    ContactComponent,
  },
  props: {
    entity: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(['self', 'entitySectors', 'entityTypes', 'challenges']),
    isPublic: {
      get() {
        return this.entity?.is_public;
      },
      set(value) {
        this.$store.dispatch('post', {
          url: 'entitypublish',
          id: this.entity.id,
          data: { is_public: value },
        }).then(({ data }) => {
          this.$store.dispatch('patchModel', {
            model: 'entity',
            data: { is_public: data.is_public },
          });
        });
      },
    },
  },
  methods: {
    filterDate(datestr) {
      const date = new Date(datestr);
      return `${date.getMonth() + 1}/${date.getFullYear()}`;
    },
    filterUrl(url) {
      return url ? url.split('/')[2] : null;
    },
    filterCurrency(number) {
      const floatNumber = parseFloat(number);

      if (floatNumber <= 999999) {
        return floatNumber.toLocaleString(this.$i18n.locale);
      }

      const millions = floatNumber / 1000000;
      return `${millions.toLocaleString(this.$i18n.locale)} M`;
    },
    capitalize(str) {
      const socialname = str.toLowerCase();
      return `${socialname.charAt(0).toUpperCase()}${socialname.slice(1)}`;
    },
    getSocialIcon(platform) {
      return `url(/img/icons/social/${platform.toLowerCase()}.svg)`;
    },
    getScore(entity) {
      if (!entity.last_report) {
        return 0;
      }
      const blocks = this.challenges.reduce((acc, curr) => {
        const chall = { ...curr, isChecked: false };
        if (this.$t(`challenges_short.${entity.type}.${chall.name}`) !== '') {
          // Aggregate only challenges for the current entity type (-> has translation)
          if (Object.prototype.hasOwnProperty.call(acc, chall.phase)) acc[chall.phase].push(chall);
          else acc[chall.phase] = [chall];
        }
        return acc;
      }, {});
      const phases = Object.keys(blocks).sort().map((key) => blocks[key]);
      const entityChallenges = entity.last_report.challenges.map((c) => c.challenge);
      const entityPhases = phases.map((phase) => ({
        value: phase.reduce((acc, curr) => (
          acc + entityChallenges.indexOf(curr.name) > -1 ? 1 : 0
        ), 0),
        total: phase.length,
      }));
      return Math.round(100 * entityPhases.reduce((acc, curr) => (
        acc + ((10 * curr.value) / (curr.total * entityPhases.length))
      ), 0)) / 100;
    },
    getPopulationName(entity) {
      if (entity.type === 'EDU') {
        return this.$t('common.students');
      }
      if (entity.type === 'AP') {
        return this.$t('common.population');
      }
      return this.$t('common.employees');
    },
    getGeneralShares() {
      const relatedChallengesNames = ['sdg-page', 'sdg-portal', 'sdg-participative-portal'];

      return this.entity.last_report.challenges
        .filter((c) => relatedChallengesNames.find((name) => name === c.challenge))
        .filter((c) => c.url)
        .map((c) => ({
          type: this.$t(`challenges.${c.challenge}`),
          url: c.url || c.file,
        }));
    },
  },
};
</script>

<style lang="scss">
.entdet {
  margin-top: 20px;
  &__head {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }
  &__logo {
    flex: 1 1 100%;
    height: calc(50vw - 20px);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    align-self: center;
  }
  &__desc {
    margin-top: 20px;
    flex: 1 1 100%;
    > div {
      display: flex;
    }
  }
  &__dates {
    display: block;
    width: 100%;
    margin-top: 6px;
    font-size: 14px;
    > div {
      display: flex;
      justify-content: space-between;
      span:first-child {
        text-transform: uppercase;
        font-weight: 700;
      }
    }
  }
  &__infoblock {
    margin-top: 12px;
    align-items: flex-start;
    font-size: 14px;
    background: var(--background-color-darker);
    padding: 20px;
    border-radius: 4px;
    > div {
      display: flex;
      margin-bottom: 6px;
      span {
        &:first-child {
          flex: 0 0 20%;
          text-transform: uppercase;
          font-weight: 700;
        }
        &:last-child {
          flex: 0 0 80%;
          text-align: left;
          padding-left: 6px;
          word-break: break-word;
        }
        img {
          width: auto;
          height: 16px;
          &.pad {
            padding: 0 5px;
          }
        }
      }
    }
  }
  &__title {
    margin-bottom: 0;
  }

  &__social {
    background-repeat: no-repeat;
    display: inline-block;
    height: 16px;
    background-size: contain;
    background-position: left center;
    width: 16px;
    margin-right: 6px;
    &--twitter {
      background-image: url(/img/icons/social/twitter-blue.svg);
      &:hover { background-image: url(/img/icons/social/twitter.svg); }
    }
    &--facebook {
      background-image: url(/img/icons/social/facebook-blue.svg);
      &:hover { background-image: url(/img/icons/social/facebook.svg); }
    }
    &--linkedin {
      background-image: url(/img/icons/social/linkedin-blue.svg);
      &:hover { background-image: url(/img/icons/social/linkedin.svg); }
    }
    &--instagram {
      background-image: url(/img/icons/social/instagram-blue.svg);
      &:hover { background-image: url(/img/icons/social/instagram.svg); }
    }
    &--youtube {
      background-image: url(/img/icons/social/youtube-blue.svg);
      &:hover { background-image: url(/img/icons/social/youtube.svg); }
    }
  }
  &__contacts {
    padding: 20px
  }
  &__shares {
    text-align: center;
    .entity-share {
      margin: 0 6px;
    }
  }
}

@media screen and (min-width: 460px) {
  .entdet {
    &__logo {
      flex: 0 0 140px;
      max-width: 140px;
      height: 88px;
      margin-right: 20px;
    }
    &__desc {
      flex: 0 1 calc(100% - 160px);
      margin-top: 0;
    }
    &__dates {
      display: none;
      flex: 1 0 100%;
    }
  }
}

@media screen and (min-width: 660px) {
  .entdet {
    &__desc {
      flex: 1 1 calc(100% - 430px);
    }
    &__dates {
      display: block;
      flex: 1 0 230px;
    }
  }
}
</style>
