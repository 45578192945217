<template>
  <Page :title="$t('entity.title')" :classes="['page--entity']">

    <EntityDetailComponent
      :entity="entity"></EntityDetailComponent>

    <EntityPriorityODSComponent
      v-if="entity.last_report"
      :report="entity.last_report"></EntityPriorityODSComponent>

    <BlockSubtitle modal="evolution">{{$t('common.evolution_chart')}}</BlockSubtitle>
    <ChartSlices
      v-if="entity.last_report"
      :oncreated="true"
      :results="entityChallenges"
      :total="1"></ChartSlices>

    <EntityPhasesComponent
      v-if="entity.last_report"
      :report="entity.last_report"></EntityPhasesComponent>

  </Page>
</template>

<script>
import { mapGetters } from 'vuex';
import EntityDetailComponent from '@/components/entities/detail/EntityDetailComponent.vue';
import EntityPriorityODSComponent from '@/components/entities/priorityods/EntityPriorityODSComponent.vue';
import ChartSlices from '@/components/charts/ChartSlices.vue';
import EntityPhasesComponent from '@/components/entities/phases/EntityPhasesComponent.vue';

export default {
  name: 'EntityPage',
  components: {
    EntityDetailComponent,
    EntityPriorityODSComponent,
    ChartSlices,
    EntityPhasesComponent,
  },
  computed: {
    ...mapGetters(['self', 'entity']),
    userCanCreateReport() {
      return this.self?.id && (this.self.is_staff || this.entity?.creator);
    },
    entityChallenges() {
      return this.entity.last_report.challenges
        .reduce((acc, curr) => {
          acc[curr.challenge] = 1;
          return acc;
        }, {});
    },
  },
};
</script>
